<template>
	<b-modal ref="close-warning" id="close-warning" title="Warning">
		<p class="my-4">Warning: Make sure to save all work before closing</p>
		<template #modal-footer>
			<b-button @click="hide()">
				Cancel
			</b-button>
			<b-button variant="info" @click="save">
				Save
			</b-button>
			<b-button v-if="showClose" variant="danger" @click="close">
				Close
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import saveMixin from "../mixins/saveMixin"

export default {
	name: 'TheWarningModal',
	mixins: [
		saveMixin
	],
	data() {
		return {
			showClose: true
		}
	},
	mounted() {
		this.$root.$on('show-warning-modal', () => {
			this.showClose = false;
			this.$refs['close-warning'].show();
		});
		this.$root.$on('show-close-modal', () => {
			console.log('showing close modal')
			this.showClose = true;
			this.$refs['close-warning'].show();
		});
	},
	methods: {
		hide() {
			this.$refs['close-warning'].hide();
		},
		close() {
			// Reset store
			this.$store.dispatch('resetStore');
			this.$refs['close-warning'].hide();
		}
	}
}
</script>

<style>
</style>