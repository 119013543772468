import { mapGetters } from 'vuex';
import filenameMixin from './filenameMixin';

export default {
	mixins: [filenameMixin],
	computed: {
		...mapGetters('references', ['getRefsArray']),
		...mapGetters('groups', ['getAllGroupRefIds', 'getSize']),
	},
	methods: {
		download(group) {
			// Get references from store
			const references = this.getRefsArray(this.getAllGroupRefIds);
			// Filter through references and select those in current group
			const groupRefs = references.filter(
				ref => ref.group === group.key
			);

			return this.$tera.setProjectLibrary({
				title: `Save ${group.name.toLowerCase()} citations`,
				hint: `screened-${group.name.toLowerCase()}`,
				refs: groupRefs,
			});
		},
	}
}
