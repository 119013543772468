<template>
	<div id="app">
		<!-- TitleBar -->
		<b-navbar style="height: 40px;" type="dark" variant="dark" sticky>
			<b-navbar-brand href="#" variant="light">
				Screenatron
			</b-navbar-brand>
			<b-navbar-nav class="ml-auto">
				<b-button size="sm" class="mr-2" @click="openHelp" variant="info">Help</b-button>
				<b-button size="sm" v-if="refs" @click="close">Choose another file...</b-button>
			</b-navbar-nav>
		</b-navbar>

		<!-- Toolbar -->
		<div class="sticky-toolbar">
			<TheToolbar />
		</div>

		<!-- Fixed footer which is used to display extra reference information -->
		<div v-if="showEditReference" id="fixed-footer" class="resizable">
			<div id="handle"></div>
			<EditReference :reference="editReference" />
		</div>

		<!-- Spacer to push down content (to prevent content from going underneath title/toolbar) -->
		<div class="spacer">
			&nbsp;
		</div>

		<div>
			<!-- Loading spinner -->
			<LoadingSpinner />

			<!-- File Upload -->
			<template v-if="!refs">
				<b-container class="file-container">
					<FileUpload />
					<!-- <b-button class="mt-3" variant="info" @click="loadDemo">Click here to use demo library</b-button> -->
				</b-container>
			</template>

			<!-- Main Application -->
			<template v-else>
				<b-row no-gutters>
					<b-col lg="2" md="12">
						<div class="sticky-groups">
							<b-card>
								<ReferenceGroups />
								<hr>
								<ProgressChart />
								<hr>
								<IncludeExcludeKeywords />
							</b-card>
						</div>
					</b-col>
						<b-col lg="10" md="12">
							<b-card class="table-area mr-2">
								<ReferenceTable />
							</b-card>
					</b-col>
				</b-row>
			</template>

			<!-- Warning Modal -->
			<TheWarningModal />
			<!-- Save Modal -->
		</div>

	</div>
</template>

<script>
import { mapState } from "vuex";

import FileUpload from "./components/FileUpload.vue";
import ReferenceTable from "./components/ReferenceTable.vue";
import ReferenceGroups from "./components/ReferenceGroups.vue";
import IncludeExcludeKeywords from "./components/IncludeExcludeKeywords.vue";
import TheToolbar from "./components/TheToolbar.vue";
import LoadingSpinner from "./components/LoadingSpinner.vue"
import ProgressChart from './components/ProgressChart.vue';
import TheWarningModal from './components/TheWarningModal.vue';
import EditReference from './components/EditReference.vue';

import { BNavbar, BNavbarBrand, BNavbarNav, BButton, BContainer, BCard, BRow, BCol, BForm } from '@iebh/bootstrap-vue';
import Vue from 'vue';
Vue.component('b-navbar', BNavbar);
Vue.component('b-navbar-brand', BNavbarBrand);
Vue.component('b-navbar-nav', BNavbarNav);
Vue.component('b-button', BButton);
Vue.component('b-container', BContainer);
Vue.component('b-card', BCard);
Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.component('b-form', BForm);

import importMixin from "./mixins/importMixin"
import json from './assets/demo-library.json';

export default {
	name: 'App',
	mixins: [ importMixin ],
	components: {
		FileUpload,
		ReferenceTable,
		ReferenceGroups,
		IncludeExcludeKeywords,
		TheToolbar,
		LoadingSpinner,
		ProgressChart,
		EditReference,
		TheWarningModal,
	},
	computed: {
		...mapState({
			metadata: state => state.metadata.metadata,
			refs: state => state.references.refs
		}),
	},
	data() {
		return {
			demo: json.demo,
			showEditReference: false,
			editReference: {},
			// Boolean to store whether title is being edited
			isEditTitle: false
		}
	},
	mounted() {
		this.$root.$on('show-edit', reference => {
			this.editReference = reference;
			this.showEditReference = true;
			// Resizable bottom fixed div
			this.$nextTick(function () {
				var handle = document.querySelector("#handle");
				var div = document.querySelector(".resizable");
				var divElm;
				var startOffset;

				handle.style.cursor = 'row-resize';
				handle.addEventListener('mousedown', function(e) {
					e.preventDefault();
					divElm = div;
					startOffset = div.offsetHeight - (window.innerHeight - e.pageY);
				})

				document.addEventListener('mousemove', function (e) {
					if (divElm) {
						if (startOffset + (window.innerHeight - e.pageY) < 200) {
							divElm.style.height = "200px";
						} else if (startOffset + (window.innerHeight - e.pageY) > (window.innerHeight * 0.9)) {
							divElm.style.height = window.innerHeight * 0.9 + 'px';
						} else {
							divElm.style.height = startOffset + (window.innerHeight - e.pageY) + 'px';
						}
					}
				}, { passive: true });

				document.addEventListener('mouseup', function () {
					divElm = undefined;
				});
			});
		});
		this.$root.$on('hide-edit', () => {
			this.editReference = {};
			this.showEditReference = false;
		});
	},
	methods: {
		close() {
			this.$root.$emit('show-close-modal');
		},
		openHelp() {
			window.open("https://sr-accelerator.com/#/help/screenatron", "_blank");
		},
		lookForEnter(event) {
			if (event.code == "Enter" || event.which == 13) {
				this.saveTitle();
			}
		},
		saveTitle() {
			this.$store.commit('metadata/setTitle', this.$refs['title-edit'].vModelValue);
			this.isEditTitle = false;
		},
		loadDemo() {
			this.$store.commit('loading/setLoading', true);
			Promise.resolve()
				.then(() => this.parseXml(this.demo))
				// Commit library to the store
				.then(([refs, refList]) => {
					this.$store.commit('references/setRefs', { ...this.getAllRefs, ...refs });
					this.$store.commit('groups/setGroupRefIds', { key: 'all', refIds: [ ...this.getRefIds('all'), ...refList ] });
					this.$store.commit('groups/setGroupRefIds', { key: 'undecided', refIds: [ ...this.getRefIds('undecided'), ...refList ] });
					this.$store.commit('loading/setLoading', false);
				})
		},
	}
}
</script>

<style>
#app {
	min-height: 100vh;
	background-color: rgb(230, 230, 230);
}
.file-container {
	height: 90vh;
}
.sticky-toolbar {
	position: fixed;
	z-index: 104;
	width: 100%;
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
#fixed-footer {
	bottom: 0px !important;
	height: 50vh;
	overflow-y: scroll;
	position: fixed;
	z-index: 100;
	width: 100%;
}
#handle {
	position:sticky;
	top: 0px;
	left: 0px;
	width: calc(100% - 70px);
	height: 40px;
	z-index: 102;
	margin-bottom: -40px;
}
.spacer {
	width: 100%;
	height: 40px;
}
.sticky-groups {
	height: calc(100vh - 80px);
	overflow-y: scroll;
}
.table-area {
	height: calc(100vh - 80px);
}
</style>
