import { v4 } from 'uuid';
import { mapGetters } from 'vuex'

export default {
	name: 'FileUpload',
	computed: {
		...mapGetters('references', ['getAllRefs']),
		...mapGetters('groups', ['getRefIds']),
	},
	data() { return {
		file: null,
	}},
	methods: {
		importFile(file) {
			this.$store.commit('loading/setLoading', true);
			Promise.resolve()
				.then(()=> file.getRefs())
				.then(refs => {
					let refsById = {};
					return [
						refsById,
						refs.map(ref => {
							let id = v4();
							refsById[id] = {
								id,
								group: null,
								...ref,
							};
							return id;
						}),
					];
				})
				// Commit library to the store
				.then(([refsById, refIdList]) => {
					this.$store.commit('references/setRefs', { ...this.getAllRefs, ...refsById });
					this.$store.commit('groups/setGroupRefIds', { key: 'all', refIds: [ ...this.getRefIds('all'), ...refIdList ] });
					this.$store.commit('groups/setGroupRefIds', { key: 'undecided', refIds: [ ...this.getRefIds('undecided'), ...refIdList ] });
					this.$store.commit('loading/setLoading', false);
				})
		},
		getFileExtention(name) {
			return "." + name.split(".").pop();
		},
		readFile(file) {
			return new Promise(resolve => {
				// Read file extention
				const fileExtention = this.getFileExtention(file.name);
				const fileType = this.fileTypeOptions.find(
					({ value }) => value.extention === fileExtention
				)?.value;
				if (typeof fileType === "undefined")
					console.log("Error: Unsuported file extention");
				else this.fileType = fileType;
				// Convert input file into a buffer
				const reader = new FileReader();
				reader.readAsText(file);
				reader.onloadend = () => resolve(reader.result);
			});
		},
	}
}
