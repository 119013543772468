<template>
	<div>
		<b-button-toolbar class="bg-secondary">
			<b-button-group>
				<!-- FILE -->
				<b-dropdown right text="File" class="custom-shadow">
					<b-dropdown-item @click="callLoadFile()">Load</b-dropdown-item>
					<b-dropdown-item v-if="refs" @click="save()">Save</b-dropdown-item>
					<!-- <b-dropdown-item>Save As</b-dropdown-item> -->
					<b-dropdown-divider></b-dropdown-divider>
					<input type="file" @change="callImportFile($event.target.files[0])" ref="importFile"
						style="display: none" accept=".xml, .csv, .json, .nbib, .ris, .tsv">
					<b-dropdown-item @click="$refs.importFile.click()">Import References</b-dropdown-item>
					<b-dropdown-item v-if="refs" @click="showModal(true)"
						v-b-modal.download-modal>Export</b-dropdown-item>
					<!-- <b-dropdown-item>Export As</b-dropdown-item> -->
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item @click="loadDemo()">Use demo library</b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item v-if="refs" @click="close">Close</b-dropdown-item>
				</b-dropdown>

				<!-- VIEW -->
				<b-dropdown v-if="refs" right text="View" class="custom-shadow">
					<b-dropdown-group header="Abstracts">
						<b-dropdown-item @click="$root.$emit('show-abstract', true)">Show All
							Abstracts</b-dropdown-item>
						<b-dropdown-item @click="$root.$emit('show-abstract', false)">Hide All
							Abstracts</b-dropdown-item>
					</b-dropdown-group>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-group header="Table Fields (drag and drop)">
						<ReferenceTableFieldPicker />
					</b-dropdown-group>
				</b-dropdown>

				<!-- REFERENCE -->
				<b-dropdown v-if="refs" right text="Reference" class="custom-shadow">
					<!-- <b-dropdown-item @click="$root.$emit('select-all')">Select All</b-dropdown-item> -->
					<b-dropdown-item @click="$root.$emit('show-single-abstract')">
						Show/Hide Abstract
						<span class="text-muted">({{ formatHotkeys("show-single-abstract") }})</span>
					</b-dropdown-item>
					<b-dropdown-item @click="$root.$emit('edit-reference')">
						Edit Reference
						<span class="text-muted">(Double Click)</span>
					</b-dropdown-item>
					<b-dropdown-item @click="$root.$emit('remove-reference-group')">
						Remove Reference Group / Place in Undecided
						<span class="text-muted">({{ formatHotkeys("remove-reference-group") }})</span>
					</b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item @click="$root.$emit('copy-title-text')">
						Copy Title Text
						<span class="text-muted">({{ formatHotkeys("copy-title-text") }})</span>
					</b-dropdown-item>
					<b-dropdown-item @click="$root.$emit('search-database', 'bond-library')">
						Search Bond Library for Article
						<span class="text-muted">({{ formatHotkeys("search-database (bond-library)") }})</span>
					</b-dropdown-item>
					<b-dropdown-item @click="$root.$emit('search-database', 'pubmed')">
						Search PubMed for Article
						<span class="text-muted">({{ formatHotkeys("search-database (pubmed)") }})</span>
					</b-dropdown-item>
					<b-dropdown-item @click="$root.$emit('search-database', 'google-scholar')">
						Search Google Scholar for Article
						<span class="text-muted">({{ formatHotkeys("search-database (google-scholar)") }})</span>
					</b-dropdown-item>
				</b-dropdown>

				<!-- HELP -->
				<b-button @click="openSettings">Settings</b-button>
				<b-button @click="openHelp">Help</b-button>
				<b-button v-if="refs" @click="openSyncGroups">Sync Groups</b-button>
			</b-button-group>
		</b-button-toolbar>

		<!-- EXPORT MODAL -->
		<b-modal v-if="isShowModal" @close="showModal(false)" id="download-modal" title="Export" :hide-footer="true">
			<b-row class="mb-4" v-for="group in customGroups" :key="group.key">
				<b-col cols="7"><span>{{ group.name }} Group - ({{ getSize(group.key) }})</span></b-col>
				<b-col><b-button @click="download(group)">Download</b-button></b-col>
			</b-row>
		</b-modal>
		<!-- SETTINGS MODAL -->
		<TheSettingsModal />
		<!-- SYNC GROUPS MODAL -->
		<TheSyncGroupsModal />
	</div>
</template>

<script>
import exportMixin from "../mixins/exportMixin";
import importMixin from "../mixins/importMixin";
import loadMixin from "../mixins/loadMixin";
import saveMixin from "../mixins/saveMixin";

import ReferenceTableFieldPicker from "./ReferenceTableFieldPicker.vue";
import TheSettingsModal from './TheSettingsModal.vue';
import TheSyncGroupsModal from './TheSyncGroupsModal.vue';

import { mapGetters, mapState } from "vuex";

import { BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BDropdownDivider, BDropdownGroup, BModal, VBModal } from '@iebh/bootstrap-vue';
import Vue from 'vue';
Vue.component('b-button-toolbar', BButtonToolbar);
Vue.component('b-button-group', BButtonGroup);
Vue.component('b-dropdown', BDropdown);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-dropdown-divider', BDropdownDivider);
Vue.component('b-dropdown-group', BDropdownGroup);
Vue.component('b-modal', BModal);
Vue.directive('b-modal', VBModal);

export default {
	name: 'TheToolbar',
	mixins: [
		exportMixin,
		importMixin,
		loadMixin,
		saveMixin
	],
	components: {
		ReferenceTableFieldPicker,
		TheSettingsModal,
		TheSyncGroupsModal
	},
	computed: {
		...mapState({
			refs: state => state.references.refs
		}),
		...mapGetters('groups', ['customGroups']),
		...mapGetters('hotkeys', ['getHotkeysForGroup']),
		formatHotkeys() {
			return action => {
				let hotkeys = this.getHotkeysForGroup(action);
				if (hotkeys && hotkeys.length > 0) {
					return hotkeys.join(" or ");
				} else {
					return "";
				}
			}
		}
	},
	data() {
		return {
			isShowModal: false,
		}
	},
	methods: {
		callLoadFile() {
			this.loadFile();
		},
		callImportFile(e) {
			this.importFile(e);
			this.$refs.importFile.value = null;
		},
		close() {
			this.$root.$emit('show-close-modal');
		},
		openSettings() {
			console.log("SETTINGS")
			this.$root.$emit('show-settings-modal');
		},
		openSyncGroups() {
			this.$root.$emit('show-sync-groups-modal');
		},
		openHelp() {
			window.open("https://sr-accelerator.com/#/help/screenatron", "_blank");
		},
		showModal(isShow) {
			this.isShowModal = isShow;
		},
		loadDemo() {
			this.$store.commit('loading/setLoading', true);
			Promise.resolve()
				.then(() => this.parseXml(this.demo))
				// Commit library to the store
				.then(([refs, refList]) => {
					this.$store.commit('references/setRefs', { ...this.getAllRefs, ...refs });
					this.$store.commit('groups/setGroupRefIds', { key: 'all', refIds: [ ...this.getRefIds('all'), ...refList ] });
					this.$store.commit('groups/setGroupRefIds', { key: 'undecided', refIds: [ ...this.getRefIds('undecided'), ...refList ] });
					this.$store.commit('loading/setLoading', false);
				})
		},
	}
}
</script>

<style>
.dropdown.custom-shadow .dropdown-menu {
	box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5) !important;
}
</style>
